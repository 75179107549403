

export class DefaultLayout {


    public container : "container" | "container-fluid" = "container";


    public break1: "sm" | "md" | "lg" | "xl" | "xxl" = "xl";

}
